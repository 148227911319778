import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()
const controllers = import.meta.globEager('./**/*_controller.js')
registerControllers(application, controllers)

// Import and register TailwindCSS Components
import { Dropdown, Toggle, Popover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('toggle', Toggle)
application.register('popover', Popover)
