import { Controller } from '@hotwired/stimulus'

export default class NestedFormController extends Controller {
  static targets = [ "items", "template" ]
  static classes = [ "remove", "item" ]

  connect() {
    if(this.hasItemClass) {
      this._itemClass = this.itemClass
    } else {
      this._itemClass = "nested-item"
    }
  }

  add(e) {
    e.preventDefault()

    const beforeEvent = new CustomEvent('nested_form_controller.add.before', { detail: this })
    document.dispatchEvent(beforeEvent)

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.itemsTarget.insertAdjacentHTML('beforeend', content)

    const afterEvent = new CustomEvent('nested_form_controller.add.after', { detail: this })
    document.dispatchEvent(afterEvent)
  }

  addByEnter(e) {
    if (e.keyCode === 13) {
      this.add(e)
    }
  }

  remove(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this._itemClass)

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1

      if (this.hasRemoveClass) {
        wrapper.classList.add(this.removeClass)
      } else {
        wrapper.style.display = "none"
      }
    }
  }
}
