import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class ReloadFormController extends Controller {
  static targets = [ "field" ]

  async reload(event) {
    event.preventDefault()
    const request = new FetchRequest('get', this.endpoint, { responseKind: "turbo-stream" })
    await request.perform()
  }

  get endpoint() {
    return '?' + this.params.toString()
  }

  get params() {
    let params = new URLSearchParams(window.location.search);
    this.fieldTargets.forEach(field => {
      params.set(field.name, field.value)
    })
    return params
  }
}
