import '@rails/ujs'
import "@hotwired/turbo"

// import ActiveStorage from '@rails/activestorage'

// Import all channels.
// import.meta.globEager('./**/*_channel.js')

// ActiveStorage.start()

// Stimulus Controllers
import '../controllers/index'

// Other
import '../other/icons'

// Custom Turbo Actions
import '../other/turbo-actions'

// Charts
import "chartkick/chart.js"

// Fixes for CSP nonce
document.addEventListener('turbo:before-fetch-request', (event) => {
  // Turbo Drive does not send a referrer like turbolinks used to, so let's simulate it here
  event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href
  event.detail.fetchOptions.headers['X-Turbo-Nonce'] = document.head.querySelector('meta[name="csp-nonce"]')?.getAttribute("content")
})

document.addEventListener("turbo:before-cache", function() {
  let scriptTagsToAddNonces = document.querySelectorAll("script[nonce]");
  for (var element of scriptTagsToAddNonces) {
    element.setAttribute('nonce', element.nonce);
  }
});
