import { Controller } from '@hotwired/stimulus'

export default class SyncValueController extends Controller {
  static targets = [ "field" ]

  sync(e) {
    this.fieldTargets.forEach(field => {
      field.value = e.target.value
    })
  }
}
