import { Controller } from '@hotwired/stimulus'

export default class ClipboardController extends Controller {
  static values = { text: String }

  copy(e) {
    e.preventDefault()
    let _this = this
    navigator.clipboard.writeText(this.textValue).then(function() {
      _this.handleState("text-green-500")
    }, function() {
      _this.handleState("text-red-500")
    })
  }

  handleState(colorClass) {
    this.element.classList.add(colorClass, `hover:${colorClass}`);
    setInterval(() => this.element.classList.remove(colorClass, `hover:${colorClass}`), 2000)
  }
}
