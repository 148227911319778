// Fontawesome
import {config, library, dom} from '@fortawesome/fontawesome-svg-core'

// Change the config to fix the flicker
config.mutateApproach = 'sync'

// Font Awesome Icons
import {
  faUserCircle, faBell, faSave, faPencilAlt, faTimes, faCircleExclamation, faCircleInfo, faCircleCheck, faTimesSquare,
  faFileAlt, faTrashAlt, faRedo, faThumbsUp, faThumbsDown, faStream, faSpinner, faCopy, faClone
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faUserCircle, faBell, faSave, faPencilAlt, faTimes, faCircleExclamation, faCircleInfo, faCircleCheck, faTimesSquare,
  faFileAlt, faTrashAlt, faRedo, faThumbsUp, faThumbsDown, faStream, faSpinner, faCopy, faClone
)

dom.watch()
